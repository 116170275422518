type CatalogData = Array<[string, Array<[string, string]>]>;

export const data: CatalogData = [
  [
    "キャスコーナーM・H級",
    [
      ["PDF（1.2MB）", "/catalogs/P3,4.pdf"],
      ["PDF（1.9MB）", "/catalogs/P5,6.pdf"],
    ],
  ],
  ["サイレントキャスコーナー", [["PDF（1.8MB）", "/catalogs/P7,8.pdf"]]],
  ["セフティ・スロープ", [["PDF（1.7MB）", "/catalogs/P9,10.pdf"]]],
  ["ハイステップ・コーナー", [["PDF（1.2MB）", "/catalogs/P11,12.pdf"]]],
  [
    "ライト・ステップ・コーナー 、安心スロープ",
    [["PDF（1.2MB）", "/catalogs/P13,14.pdf"]],
  ],
  [
    "アルミスロープ",
    [
      ["PDF（696KB）", "/catalogs/P15,16.pdf"],
      ["PDF（2.1MB）", "/catalogs/P17,18.pdf"],
    ],
  ],
  [
    "フリースロープ",
    [
      ["PDF（1.2MB）", "/catalogs/P19,20.pdf"],
      ["PDF（1.3MB）", "/catalogs/P21,22.pdf"],
    ],
  ],
  [
    "カーストッパー",
    [
      ["PDF（1.2MB）", "/catalogs/P23,24.pdf"],
      ["PDF（717KB）", "/catalogs/P25,26.pdf"],
      ["PDF（1.5MB）", "/catalogs/P27,28.pdf"],
    ],
  ],
  ["サイクル・ポジション", [["PDF（1.2MB）", "/catalogs/P29,30.pdf"]]],
];
