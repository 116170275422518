import React from "react";

import { Props } from "../../types/Heading04";

/**
 * 見出し4、h2をメインに想定
 */
export const Presenter: React.VFC<Props> = ({ label, TagName = "h2" }) => {
  return (
    <TagName className="mb-6 border-b-2 border-solid border-primary pb-4 text-xl font-bold pc:mb-8 pc:text-2xl">
      {label}
    </TagName>
  );
};
