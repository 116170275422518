import React from "react";

import { Props } from "../../types/Link03";

/**
 * テキストリンクです
 * 別ダブで開きます
 * カタログダウンロードに使用
 */
export const Presenter: React.VFC<Props> = ({ label, link }) => {
  return (
    <div className="relative inline-block pr-5 text-primary duration-300 hover:underline">
      <a href={link} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    </div>
  );
};
