import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Heading01 } from "../components/Heading01";
import { Heading04 } from "../components/Heading04";
import { Layout } from "../components/Layout/index";
import { data } from "../components/CatalogData";
import bgimgPc from "../images/h1/h1catalog.png";
import bgimgSp from "../images/h1/h1catalog_sp.png";
import { Link03 } from "../components/Link03";
import MyLink from "../components/MyLink";

const CatalogPage: React.VFC = () => {
  return (
    <Layout
      title="カタログダウンロード"
      description="ミスギのカタログダウンロードページです。"
    >
      <Heading01
        label="CATALOG"
        smallLabel="カタログダウンロード"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "カタログダウンロード" }]} />
      <div className="l-container-sm pt-8 pb-16 pc:pt-16 pc:pb-32">
        <p className="txt-sm pc:text-base">
          PDFファイルの閲覧には「Adobe Reader」が必要です。Adobe
          のサイトよりダウンロード・インストールをして下さい。
        </p>
        <div className="mt-4">
          <MyLink to="https://www.adobe.com/jp/acrobat/pdf-reader.html">
            <StaticImage
              src="../images/getacro.png"
              alt="PDFファイルの閲覧には「Adobe Reader」が必要です。"
            />
          </MyLink>
        </div>
        <div className="mt-16">
          <Heading04 label={"製品カタログ"} TagName={"h2"} />
        </div>

        <ul className="mt-8 mb-16 pc:mb-32">
          {data.map(
            (item, index) =>
              item && (
                <li
                  key={index}
                  className="mt-10 flex flex-col text-base pc:flex-row"
                >
                  <p
                    className="relative pl-5 text-base before:absolute before:left-0 before:top-[.5em]
                before:h-2 before:w-2 before:rounded-full before:bg-primary
                before:content-[''] pc:min-w-[400px]"
                  >
                    {item[0]}
                  </p>
                  <div className="ml-6 mt-3 flex pc:mt-0 pc:ml-[140px]">
                    <ul className="flex flex-col space-y-3">
                      {item[1].map((pdf, i) => (
                        <li className="min-w-[240px] text-[14px]" key={i}>
                          <Link03 label={pdf[0]} link={pdf[1]} />
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              )
          )}
        </ul>
      </div>
    </Layout>
  );
};

export default CatalogPage;
